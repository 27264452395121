import { ClockConfig, TemplateBackstageLogoIcon } from '@backstage/plugin-home';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const tools = [
  {
    url: '/catalog-import',
    label: 'Import Repo',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://evo-docs.access-workspace.com/',
    label: 'Access Evo',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://compass.accessacloud.com',
    label: 'Compass',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://ux.accessacloud.com',
    label: 'Experience Language',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://tag-v4.docs.access-workspace.com/',
    label: 'Tag Components',
    icon: <TemplateBackstageLogoIcon />,
  },
  {
    url: 'https://backstage.io/docs',
    label: 'Docs',
    icon: <TemplateBackstageLogoIcon />,
  },
];

export const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const clockConfigs: ClockConfig[] = [
  {
    label: '🇺🇸 NYC',
    timeZone: 'America/New_York',
  },
  {
    label: '🇬🇧 LBH',
    timeZone: 'UTC',
  },
  {
    label: '🇷🇴 TIM',
    timeZone: 'Europe/Bucharest',
  },
  {
    label: '🇦🇺 SYD',
    timeZone: 'Australia/Sydney',
  },
];
