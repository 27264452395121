const bannedChars = /[|?.,/\\]/;

export function sanitizeTags(tags: string[]): string[] {
  return tags.map(tag => tag.replace(bannedChars, ''));
}

export function serializeTags(tags: string[]) {
  return tags.join('|');
}

export function deserializeTags(serializedTags: string) {
  return serializedTags.split('|').filter(it => !!it);
}
