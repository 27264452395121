/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import useAsync from 'react-use/esm/useAsync';
import { makeStyles, Theme } from '@material-ui/core';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
  CatalogApi,
  useEntityOwnership,
  EntityListProvider,
  CatalogFilterLayout,
  UserListPicker,
  EntityOwnerPicker,
  EntityTagPicker,
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

import {
  CodeSnippet,
  Content,
  HeaderTabs,
  Progress,
  WarningPanel,
  SupportButton,
  ContentHeader,
} from '@backstage/core-components';
import {
  createRouteRef,
  useApi,
  useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
  DocsCardGrid,
  DocsTable,
  EntityListDocsTable,
  PanelConfig,
  TabConfig,
  TechDocsPageWrapper,
  TechDocsPicker,
} from '@backstage/plugin-techdocs';

const panels = {
  DocsTable: DocsTable,
  DocsCardGrid: DocsCardGrid,
};

const useStyles = makeStyles<Theme, PanelConfig>({
  panelContainer: {
    marginBottom: '2rem',
    ...props => (props.panelCSS ? props.panelCSS : {}),
  },
});

interface CustomPanelProps {
  config: PanelConfig;
  entities: Entity[];
  index: number;
}

const CustomPanel = ({ config, entities, index }: CustomPanelProps) => {
  const classes = useStyles(config);
  const { loading: loadingOwnership, isOwnedEntity } = useEntityOwnership();

  const Panel = panels[config.panelType];

  const shownEntities = entities.filter(entity => {
    if (config.filterPredicate === 'ownedByUser') {
      if (loadingOwnership) {
        return false;
      }
      return isOwnedEntity(entity);
    }

    return (
      typeof config.filterPredicate === 'function' &&
      config.filterPredicate(entity)
    );
  });

  return (
    <>
      <ContentHeader title={config.title} description={config.description}>
        {index === 0 ? (
          <SupportButton>
            Discover documentation in your ecosystem.
          </SupportButton>
        ) : null}
      </ContentHeader>
      <div className={classes.panelContainer}>
        <Panel data-testid="techdocs-custom-panel" entities={shownEntities} />
      </div>
    </>
  );
};

function useTechDocsEntities() {
  const catalogApi: CatalogApi = useApi(catalogApiRef);

  const {
    value: entities,
    loading,
    error,
  } = useAsync(async () => {
    const response = await catalogApi.getEntities({
      filter: {
        'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
      },
      fields: [
        'apiVersion',
        'kind',
        'metadata',
        'relations',
        'spec.owner',
        'spec.type',
      ],
    });
    return response.items.filter(
      (entity: Entity) =>
        !!entity.metadata.annotations?.['backstage.io/techdocs-ref'],
    );
  });

  return { entities, loading, error };
}

/**
 * Props for {@link TechDocsCustomHome}
 *
 * @public
 */
export interface TechDocsCustomHomeProps {
  tabsConfig: CustomTabConfig[];
}

export interface CustomTabConfig extends TabConfig {
  slug: string;
}

export const tabbedPageRouteRef = createRouteRef({
  id: 'techdocs:index-page',
  params: ['*'],
});

export const CustomTechDocsHome = (props: TechDocsCustomHomeProps) => {
  const { tabsConfig } = props;
  const params = useRouteRefParams(tabbedPageRouteRef);

  const selectedTab = params['*'] || 'all';
  const selectedTabIndex = tabsConfig.findIndex(
    item => item.slug === selectedTab,
  );

  const { entities, loading, error } = useTechDocsEntities();

  const currentTabConfig = tabsConfig[selectedTabIndex];

  if (loading) {
    return (
      <TechDocsPageWrapper>
        <Content>
          <Progress />
        </Content>
      </TechDocsPageWrapper>
    );
  }

  if (error) {
    return (
      <TechDocsPageWrapper>
        <Content>
          <WarningPanel
            severity="error"
            title="Could not load available documentation."
          >
            <CodeSnippet language="text" text={error.toString()} />
          </WarningPanel>
        </Content>
      </TechDocsPageWrapper>
    );
  }

  return (
    <TechDocsPageWrapper>
      <HeaderTabs
        selectedIndex={selectedTabIndex}
        tabs={tabsConfig.map(item => ({
          id: item.slug,
          label: item.label,
        }))}
      />
      <Content data-testid="techdocs-content">
        {selectedTab === 'all' ? (
          <EntityListProvider>
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters>
                <TechDocsPicker />
                <UserListPicker />
                <EntityOwnerPicker />
                <EntityTagPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <EntityListDocsTable />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        ) : (
          currentTabConfig.panels.map((config, index) => (
            <CustomPanel
              key={index}
              config={config}
              entities={!!entities ? entities : []}
              index={index}
            />
          ))
        )}
      </Content>
    </TechDocsPageWrapper>
  );
};
