import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'acc-techradar',
});

export const editorRouteRef = createSubRouteRef({
  id: 'acc-techradar-editor',
  parent: rootRouteRef,
  path: '/edit/:id',
});
