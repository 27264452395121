import React from 'react';
import { ClarityComponent } from './Clarity';

export function Integrations() {
  return (
    <>
      <ClarityComponent />
    </>
  );
}
