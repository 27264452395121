import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { TechRadarClient } from './api/tech-radar-client';

export const accTechradarPlugin = createPlugin({
  id: 'acc-techradar',
  routes: {
    root: rootRouteRef,
  },
  apis: [TechRadarClient.createDefaultApiFactory()],
});

export const AccTechradarPage = accTechradarPlugin.provide(
  createRoutableExtension({
    name: 'AccTechradarPage',
    component: () => import('./components/RadarIndex').then(m => m.RadarIndex),
    mountPoint: rootRouteRef,
  }),
);
