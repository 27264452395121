import { Config } from '@backstage/config';
import {
  CreateRadarDto,
  EditRadarDto,
  PrResultDto,
  RadarIndexResult,
  RadarLoadResult,
  ScmAuthorizationHeader,
} from '@internal/backstage-plugin-acc-techradar-common';
import {
  configApiRef,
  createApiFactory,
  createApiRef,
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';

async function toJson(request: Response) {
  if (request.ok) {
    return request.json();
  }

  return request
    .json()
    .then(it => {
      if (!it) {
        return Promise.reject(
          new Error(`Failed to fetch. Cause: ${request.statusText}`),
        );
      }
      const maybeError: Error = it.error;
      if (maybeError) {
        return new Error(`${maybeError.name}:${maybeError.message}`);
      }
      return it;
    })
    .catch(() => {
      return new Error(`Request failed with status: ${request.statusText}`);
    })
    .then(it => Promise.reject(it));
}

export const accTechRadarClientRef = createApiRef<TechRadarClient>({
  id: 'acc-techradar.radar-client',
});

async function withAuth(
  identity: IdentityApi,
  options: RequestInit = {},
): Promise<RequestInit> {
  const credentials = await identity.getCredentials();
  const { headers = {} } = options;
  return {
    ...options,
    headers: {
      ...headers,
      Authorization: `Bearer ${credentials.token}`,
    },
  };
}

export interface RadarAuthor {
  name?: string;
  userToken?: string;
  email?: string;
}

export class TechRadarClient {
  private _baseUrl: string;

  constructor(config: Config, private _identity: IdentityApi) {
    this._baseUrl = config.get('backend.baseUrl');
  }

  public async getRadarList(): Promise<RadarIndexResult> {
    return fetch(
      `${this._baseUrl}/api/acc-techradar/load`,
      await withAuth(this._identity),
    ).then(toJson);
  }

  public async getRadar(id: string): Promise<RadarLoadResult> {
    return fetch(
      `${this._baseUrl}/api/acc-techradar/load/${id}`,
      await withAuth(this._identity),
    ).then(toJson);
  }

  public async updateRadar(
    id: string,
    payload: EditRadarDto,
    userToken?: string,
  ): Promise<PrResultDto> {
    return fetch(
      `${this._baseUrl}/api/acc-techradar/radar/${id}`,

      await withAuth(this._identity, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          [ScmAuthorizationHeader]: userToken ?? '',
        },
        body: JSON.stringify(payload),
      }),
    ).then(toJson);
  }

  public async createRadar(
    payload: CreateRadarDto,
    userToken?: string,
  ): Promise<PrResultDto> {
    return fetch(
      `${this._baseUrl}/api/acc-techradar/radar`,

      await withAuth(this._identity, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          [ScmAuthorizationHeader]: userToken ?? '',
        },
        body: JSON.stringify(payload),
      }),
    ).then(toJson);
  }

  public static createDefaultApiFactory() {
    return createApiFactory({
      api: accTechRadarClientRef,
      deps: {
        config: configApiRef,
        identity: identityApiRef,
      },
      factory({ config, identity }): TechRadarClient {
        return new TechRadarClient(config, identity);
      },
    });
  }
}

export const useTechRadarClient = () => useApi(accTechRadarClientRef);
