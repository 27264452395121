import z from 'zod';

export const idSchema = z
  .string()
  .regex(/^[a-z0-9\-]+$/, 'Must contain only lowercase letters numbers  and -');
export const timelineEntrySchema = z.object({
  ringId: z.string(),
  description: z.string().min(1, 'Please state your motivation!'),
  date: z.coerce.date().transform(it => new Date(it)),
  moved: z.optional(z.any()),
});

export const radarEntryWithTagsSchema = z.object({
  id: idSchema,
  key: z.string().min(1),
  title: z.coerce.string().min(1, 'Please provide a title'),
  description: z.optional(z.string()),
  url: z.optional(z.string()),
  quadrant: z.string().min(1, 'Please select a quadrant'),
  tags: z.optional(z.array(z.string())),
  timeline: z.array(timelineEntrySchema),

  links: z.optional(
    z.array(
      z.object({
        url: z.string(),
        title: z.string(),
      }),
    ),
  ),
});

export const prSchema = z.object({
  title: z.string().min(1, 'The title is required'),
  description: z.string().min(1, 'The description is required'),
});

export const radarQuadrantSchema = z.object({
  id: idSchema,
  name: z.string().min(1, 'Quadrant cannot be empty'),
});
const radarRingSchema = z.object({
  id: idSchema,
  name: z.string(),
  color: z
    .string()
    .startsWith('#', 'expected valid color hex value: #ABC123')
    .length(7, 'expected valid color hex value: #ABC123'),

  description: z.optional(z.string()),
});
export const radarWithTagsSchema = z.object({
  quadrants: z.array(radarQuadrantSchema),
  rings: z.array(radarRingSchema),
  entries: z.array(radarEntryWithTagsSchema),
});

export const radarLoadResultSchema = z.object({
  id: idSchema,
  name: z.string(),
  owner: z.optional(z.string()),
  tags: z.array(z.string()),
  radar: radarWithTagsSchema,
});

export const radarOverviewSchema = z.object({
  id: idSchema,
  name: z.string(),
  owner: z.optional(z.string()),
  definition: z.string(),
});

const radarDefinitionSchema = radarWithTagsSchema.extend({
  entries: z.array(z.string()),
});

export const radarIndexSchema = z.object({
  data: z.array(radarOverviewSchema),
  file: z.optional(z.string()),
});

export type WithFile<T> = { file: string; value: T };

export type RadarEntryWithTags = z.infer<typeof radarEntryWithTagsSchema>;
export type RadarWithTags = z.infer<typeof radarWithTagsSchema>;
export type RadarLoadResult = z.infer<typeof radarLoadResultSchema>;
export type RadarOverview = z.infer<typeof radarOverviewSchema>;
export type RadarDefinition = z.infer<typeof radarDefinitionSchema>;
export type RadarIndexResult = z.infer<typeof radarIndexSchema>;
export type RadarQuadrant = z.infer<typeof radarQuadrantSchema>;
export type TimelineEntry = z.infer<typeof timelineEntrySchema>;
export type RadarEntryFile = WithFile<RadarEntryWithTags[]>;
export type RadarRing = z.infer<typeof radarRingSchema>;

export interface RadarDraft {
  id: string;
  definition: RadarDefinition;
  radarIndex: RadarOverview[];
  entryFiles: RadarEntryFile[];
}
