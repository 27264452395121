import { useEffect } from 'react';

export function ClarityComponent() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
    console.log('Clarity script loaded');
     (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "no5dzl15nt");
    `;
      document.body.appendChild(script);
    }
  }, []);
  return null;
}
