import z from 'zod';
import { idSchema, radarQuadrantSchema, radarWithTagsSchema } from './entities';
import { AnyChange } from './changes';

export const prDetailsSchema = z.object({
  title: z.string().min(1),
  description: z.string().min(1),
  branch: z.optional(z.string()),
  author: z.optional(
    z.object({
      name: z.string(),
      url: z.string(),
    }),
  ),
});
export const radarDetailsModelSchema = radarWithTagsSchema.extend({
  id: idSchema,
  name: z.string().min(1, 'Radar Name is Required'),
  owner: z.optional(z.string()),
});
export const createRadarDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  owner: z.string(),
  pr: prDetailsSchema,
  radar: radarWithTagsSchema.extend({
    quadrants: z.array(radarQuadrantSchema).length(4),
  }),
});

export type CreateRadarDto = z.output<typeof createRadarDtoSchema>;

export interface PrAuthor {
  name: string;
  url: string;
}

export interface PrDetails {
  title: string;
  description: string;
  branch?: string;
  author?: PrAuthor;
}

export interface PrResultDto {
  status: 'created' | 'no-changes';
  url?: string;
}

export type EditRadarDto = {
  pr: PrDetails;
  changes: AnyChange[];
};
export const ScmAuthorizationHeader = 'ScmAuthorization';
