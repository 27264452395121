import {
  TechRadarApi,
  techRadarApiRef,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';
import {
  ProblemDetails,
  RadarLoadResult,
  ValidationProblem,
} from '@internal/backstage-plugin-acc-techradar-common';
import { TechRadarClient, accTechRadarClientRef } from './tech-radar-client';
import { createApiFactory, useApi } from '@backstage/core-plugin-api';
import { deserializeTags } from '../util/sanitizers';

const emptyRadar = {
  rings: [],
  quadrants: [],
  entries: [],
};

export class AccTechRadarApi implements TechRadarApi {
  private _cache: Record<string, Promise<RadarLoadResult>> = {};

  public onValidationError: (problem: ValidationProblem) => void = () => {};

  constructor(private _api: TechRadarClient) {}

  private async _fetchRadar(id: string): Promise<RadarLoadResult> {
    const radar = await this._api.getRadar(id);
    radar.radar.entries = radar.radar.entries.map(it => {
      return {
        ...it,
        timeline: it.timeline.map(time => ({
          ...time,
          date: new Date(time.date),
        })),
      };
    });
    return radar;
  }

  loadWithCache(id: string): Promise<RadarLoadResult> {
    this._cache[id] =
      this._cache[id] ??
      this._fetchRadar(id).catch(it => {
        delete this._cache[id];
        return Promise.reject(it);
      });
    return this._cache[id];
  }

  private _handleProblemDetails(problem: ProblemDetails) {
    const validationProblem = problem as ValidationProblem;
    if (validationProblem.type === 'ValidationError') {
      this.onValidationError(validationProblem);
    }
    throw new Error(`${problem.title}:${validationProblem.detail}`);
  }

  async load(requestId: string | undefined): Promise<TechRadarLoaderResponse> {
    try {
      if (!requestId) {
        return emptyRadar;
      }
      const [newId, serializedTags] = requestId.split('?');
      const radarRef = await this.loadWithCache(newId);
      const tags = deserializeTags(serializedTags);
      const entries = radarRef.radar.entries;
      const newEntries =
        tags.length === 0
          ? entries
          : entries.filter(it => it.tags?.find(t => tags.indexOf(t) !== -1));
      return {
        ...radarRef.radar,
        entries: newEntries,
      };
    } catch (error) {
      const problem = error as ProblemDetails;
      if (problem.type) {
        this._handleProblemDetails(problem);
      }
      throw error;
    }
  }

  public static createDefaultApiFactory = () =>
    createApiFactory({
      api: techRadarApiRef,
      deps: { client: accTechRadarClientRef },
      factory({ client }) {
        return new AccTechRadarApi(client);
      },
    });
}

export const useTechRadarApi = () => useApi(techRadarApiRef) as AccTechRadarApi;
