import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  identityApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';

import { visitsApiRef, VisitsStorageApi } from '@backstage/plugin-home';
import { BackstageInsights } from '@spotify/backstage-plugin-analytics-module-insights';
import { AccTechRadarApi } from '@internal/backstage-plugin-acc-techradar';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  // Implementation that relies on a provided storageApi
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),

  AccTechRadarApi.createDefaultApiFactory(),
  BackstageInsights.createDefaultApiFactory(),
];
